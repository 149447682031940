import './App.css';
import React, { useContext, useEffect, useState } from "react";
import { AuthProvider } from "./utils/auth.js";
import { AuthContext } from "./utils/auth.js";
import { Route, Switch, BrowserRouter, Redirect, withRouter } from "react-router-dom";

import Dashboard from './screens/Dashboard.js';
import Account from './screens/Account.js';
import Api from './screens/Api.js';
import Billing from './screens/Billing.js';
import DataExtraction from './screens/DataExtraction.js';
import Login from './screens/Login.js';
import Admin from './screens/Admin.js';
import ForgotPassword from './screens/ForgotPassword.js';
import Register from './screens/Register.js';
import EmailVerificationNotice from './screens/VerifyEmail.js';

import { initializeTagManager } from "./utils/gtm.js";

import { Toaster } from 'react-hot-toast';

import { Switch as SwitchButton } from '@headlessui/react';
import { ArrowRightIcon } from '@heroicons/react/20/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const BadgeComponent = () => (
  <div className="fixed bottom-0 right-0 mb-7 transform -translate-x-1/2 bg-blue-500 text-white text-xs font-bold py-2 px-4 rounded-full z-100">
    test app
  </div>
);


const BadgeComponent2 = () => (
  <div className="fixed bottom-0 right-0 mb-7 transform -translate-x-1/2 bg-blue-500 text-white text-xs font-bold py-2 px-4 rounded-full z-100">
    dev app
  </div>
);

export default function App() {
  const environment = process.env.REACT_APP_ENV;

  useEffect(() => {
    initializeTagManager();
  }, []);

  if (environment === "pricing") {
    return (
      <SubscriptionIframe />
    );
  }

  return (
    <AuthProvider>
      <BrowserRouter>
        <div className='h-full'>
          <div>
            <Toaster
              position="top-right"
              reverseOrder={false}
              containerClassName='mt-16'
            />
          </div>

          <Switch>
            <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgot" component={ForgotPassword} />
            <Route exact path="/register" component={Register} />
            <PrivateRouteEmailVerify exact path="/verify-email" component={EmailVerificationNotice} />
            <PrivateRoute exact path="/dashboard" component={withRouter(Dashboard)} />
            <ProtectedRoute exact path="/admin" component={withRouter(Admin)} />
            <PrivateRoute path="/extractions" component={withRouter(DataExtraction)} />
            <PrivateRoute exact path="/account" component={withRouter(Account)} />
            <PrivateRoute exact path="/api" component={withRouter(Api)} />
            <PrivateRoute exact path="/billing" component={withRouter(Billing)} />
          </Switch>
        </div>
        {environment === "test" && <BadgeComponent />}
        {environment === "development" && <BadgeComponent2 />}
      </BrowserRouter>
    </AuthProvider>
  );
}

function PrivateRoute({ component: RouteComponent, ...rest }) {
  const { currentUser, isEmailVerified } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={routeProps =>
        currentUser ? (
          isEmailVerified ? (
            <RouteComponent {...routeProps} />
          ) : (
            <Redirect to="/verify-email" />
          )
        ) : (
          window.location.href = "/login"
        )
      }
    />
  );
}

function PrivateRouteEmailVerify({ component: RouteComponent, ...rest }) {
  const { currentUser, isEmailVerified } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={routeProps =>
        currentUser ? (
          isEmailVerified ? (
            <Redirect to="/dashboard" />
          ) : (
            <RouteComponent {...routeProps} />
          )
        ) : (
          window.location.href = "/login"
        )
      }
    />
  );
}


const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { currentUser } = useContext(AuthContext);
  const adminEmails = ["barzoiu.alexandru@gmail.com", "office@extracta.ai", "alexraducu03@gmail.com"];

  return (
    <Route
      {...rest}
      render={props => {
        if (currentUser && adminEmails.includes(currentUser.email)) {
          return <Component {...props} />
        } else {
          return <Redirect to="/login" />
        }
      }}
    />
  )
}

const SubscriptionIframe = () => {
  const subscriptions = [
    {
      "pages": 100,
      "price": 19,
      // "priceId": "price_1PPxpJJhJ8d6WtokEBDNsx02",
      // "priceId": "price_1POcgmJhJ8d6WtokdmULGucg", // test month
      "priceId": "price_1QA6pdJhJ8d6WtokrTIUxxX3",
      // "priceId": "price_1QA8joJhJ8d6Wtoki8rlyO3m", // test 3 months
      "productId": "prod_QGUoog9p1KCPZq",
    },
    {
      "pages": 250,
      "price": 39,
      // "priceId": "price_1PPxpfJhJ8d6WtokkpEJ2wEf",
      "priceId": "price_1QA6qZJhJ8d6WtokWrAd7Wry",
      "productId": "prod_QGUpAjvHeK1ZrQ",
    },
    {
      "pages": 500,
      "price": 69,
      // "priceId": "price_1PPxq4JhJ8d6WtokktSOR9Tp",
      "priceId": "price_1QA6rvJhJ8d6WtokwW4wi9Aj",
      "productId": "prod_QGUpwogHpSzPIR",
    },
    {
      "pages": 1000,
      "price": 129,
      // "priceId": "price_1PPxr6JhJ8d6WtokzHcmAgJg",
      "priceId": "price_1QA6uiJhJ8d6WtokCqv6xo7F",
      "productId": "prod_QGUqRAqrHdTvfm",
    },
    {
      "pages": 2000,
      "price": 219,
      // "priceId": "price_1PPxrTJhJ8d6Wtokgk6IUUEw",
      "priceId": "price_1QA6vdJhJ8d6Wtokuyt9EEtB",
      "productId": "prod_QGUrr6GgkUgAJm",
    },
    {
      "pages": 5000,
      "price": 429,
      // "priceId": "price_1PPxrtJhJ8d6WtokFOgejvus",
      "priceId": "price_1QA6wWJhJ8d6WtoktQMLmZ2m",
      "productId": "prod_QGUrMwJM1WBCQC",
    },
    {
      "pages": 10000,
      "price": 819,
      // "priceId": "price_1PPxsIJhJ8d6WtokAVswWEWa",
      // "priceId": "price_1QA6yyJhJ8d6WtokLZmMuYYg",
      "priceId": "price_1QjdmDJhJ8d6Wtok9KFDbeBr",
      "productId": "prod_QGUs72NvosNyPE",
    },
    {
      "pages": 25000,
      "price": 1890,
      // "priceId": "price_1PPxshJhJ8d6WtokESLxOQoP",
      // "priceId": "price_1QA6zrJhJ8d6Wtok0oss4uco",
      "priceId": "price_1Qjdl9JhJ8d6Wtok6Q71Ic6k",
      "productId": "prod_QGUsXrcqGbp9bj",
    },
    {
      "pages": 50000,
      "price": 3450,
      // "priceId": "price_1PPxt1JhJ8d6WtokLcsW9h9A",
      // "priceId": "price_1QA70tJhJ8d6WtokA4gdNquo",
      "priceId": "price_1QjdisJhJ8d6WtokOtzIpWg6",
      "productId": "prod_QGUs4ycz2DsHkA",
    },
  ];

  const [selectedPriceId, setSelectedPriceId] = useState(subscriptions[0].priceId);

  const changeSelectedPriceId = (priceId) => {
    setSelectedPriceId(priceId);
  }

  const handleSubscription = () => {
    window.parent.location.href = "https://app.extracta.ai/billing";
  }

  return (
    <SubscriptionContainer2
      selectedPriceId={selectedPriceId}
      subscriptions={subscriptions}
      changeSelectedPriceId={changeSelectedPriceId}
      handleSubscription={handleSubscription}
    />
  );
}

const SubscriptionContainer2 = ({ selectedPriceId, subscriptions, changeSelectedPriceId, handleSubscription }) => {
  const selectedSubscription = subscriptions.find(subscription => subscription.priceId === selectedPriceId);
  const subscriptionPrice = selectedSubscription ? selectedSubscription.price : 0;
  const subscriptionPages = selectedSubscription ? selectedSubscription.pages : 0;

  const [enabled, setEnabled] = useState(false);

  function getDiscountByPriceId(priceId) {
    switch (priceId) {
      case "price_1QjdmDJhJ8d6Wtok9KFDbeBr":
        return 0.2;
      case "price_1Qjdl9JhJ8d6Wtok6Q71Ic6k":
        return 0.15;
      case "price_1QjdisJhJ8d6WtokOtzIpWg6":
        return 0.1;
      default:
        return 0.3;
    }
  }

  const discountByPriceId = getDiscountByPriceId(selectedPriceId);
  let discountByPriceIdString = discountByPriceId * 100;
  discountByPriceIdString += "%";

  const subscriptionDiscount = enabled ? discountByPriceId : 0;
  const subscriptionDiscountedPrice = subscriptionPrice - (subscriptionPrice * subscriptionDiscount);
  const subscriptionPricePerPage = subscriptionDiscountedPrice / subscriptionPages;
  const discountAmount = subscriptionPrice * subscriptionDiscount;

  return (
    <div className="overflow-hidden flex flex-col sm:col-span-4">
      <div className="w-full">
        <div className="grid grid-cols-1 lg:grid-cols-5">
          <div className="lg:col-span-3 rounded-lg border shadow px-4 py-5 sm:p-6 flex flex-col justify-center">
            {/* <div className="mt-1 flex items-center justify-center">
              <p className="text-sm font-semibold text-gray-500 text-center">CHOOSE A PACKAGE</p>
            </div> */}

            <div className="mb-5 flex items-center justify-center">
              <p className="text-3xl font-bold text-center">How many pages do you have?</p>
            </div>

            <div className="mt-5 grid grid-cols-2 lg:grid-cols-3 gap-4">
              {subscriptions.map((subscription, index) => (
                <SubscriptionCard
                  key={index}
                  amount={subscription.pages}
                  price={subscription.price}
                  priceId={subscription.priceId}
                  productId={subscription.productId}
                  selectedPriceId={selectedPriceId}
                  onClick={changeSelectedPriceId}
                />
              ))}
            </div>
          </div>

          <div className="lg:col-span-2 rounded-lg lg:rounded-tl-none lg:rounded-bl-none border py-5 sm:p-12 sm:py-10 my-8 bg-gradient-to-r from-indigo-800 to-indigo-500 text-white shadow">
            <div className="flex flex-col items-center justify-center h-full px-8">
              <div className="flex flex-row items-center">
                <p className="text-md font-bold text-white">Pay-As-You-Go</p>
                <SwitchButton
                  checked={enabled}
                  onChange={setEnabled}
                  className={classNames(
                    enabled ? 'bg-indigo-300' : 'bg-indigo-300',
                    'mx-3 relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enabled ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-indigo-500 shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </SwitchButton>
                <p className="text-md font-bold text-white">Subscription</p>
              </div>

              <div className={classNames("mt-6 flex flex-row items-center justify-evenly w-full",)}>
                {enabled && (
                  <>
                    <div className="text-lg font-bold text-indigo-100 line-through decoration-red-500 opacity-50">
                      ${subscriptionPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </div>
                    <div className="bg-green-500/30 text-green-500 text-sm font-bold px-2 py-1 rounded-lg">
                      SAVE ${discountAmount.toFixed(2)}
                    </div>
                  </>
                )}
              </div>

              <div className={classNames(
                "mt-1 text-5xl font-bold flex flex-col lg:flex-row items-center lg:items-end justify-center",
                enabled ? '' : 'py-3.5'
              )}>
                <span>${subscriptionDiscountedPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                {enabled && (
                  <div className="ml-2 text-lg font-semibold text-indigo-200 lg:ml-2">/ month</div>
                )}
              </div>

              <div className={classNames(
                "mt-1 text-5xl font-bold flex flex-col lg:flex-row items-center lg:items-end justify-center",
                enabled ? '' : 'py-3.5'
              )}>
                {enabled && (
                  <div className="ml-2 text-lg font-semibold text-indigo-200 lg:ml-2">billed every 3 months</div>
                )}
              </div>

              <div className="mt-7 flex flex-row gap-4 items-center">
                <div className="flex flex-col items-center">
                  <div className="text-lg font-bold">{subscriptionPages.toLocaleString()}</div>
                  <p className="mt-0.5 text-sm font-semibold text-indigo-200">{"Pages" + (enabled ? " Per Month" : "")}</p>
                </div>
                <div className="h-full border-r text-indigo-200"></div>
                <div className="flex flex-col items-center">
                  <div className="text-lg font-bold">${subscriptionPricePerPage.toFixed(5)}</div>
                  <p className="mt-0.5 text-sm font-semibold text-indigo-200">Cost Per Page</p>
                </div>
              </div>

              <div className="mt-8 w-full">
                <button
                  className="w-full inline-flex items-center justify-center rounded-md bg-white px-4 py-2 text-md font-semibold text-indigo-700 shadow-sm focus:outline-none"
                  onClick={handleSubscription}
                >
                  Try It Free
                  <ArrowRightIcon className="ml-2 h-4 w-4" aria-hidden="true" />
                </button>
              </div>

              <div className="mt-8 text-center">
                {!enabled && (
                  <div className="flex flex-col items-cemter gap-3">
                    <div className="text-sm font-bold text-indigo-200 flex items-center">
                      <svg className="w-4 h-4 text-green-500 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"></path></svg>
                      <span>50 Pages Free Trial</span>
                    </div>
                    <div className="text-sm font-bold text-indigo-200 flex items-center">
                      <svg className="w-4 h-4 text-green-500 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"></path></svg>
                      <span>No monthly payments</span>
                    </div>
                    <div className="text-sm font-bold text-indigo-200 flex items-center">
                      <svg className="w-4 h-4 text-green-500 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"></path></svg>
                      <span>Buy only what you need</span>
                    </div>
                    <div className="text-sm font-bold text-indigo-200 flex items-center">
                      <svg className="w-4 h-4 text-green-500 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"></path></svg>
                      <span>Seamless API Integration</span>
                    </div>
                  </div>
                )}
                {enabled && (
                  <div className="flex flex-col items-cemter gap-3">
                    <div className="text-sm font-bold text-indigo-200 flex items-center">
                      <svg className="w-4 h-4 text-green-500 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"></path></svg>
                      <span>Subscribe and save {discountByPriceIdString}</span>
                    </div>
                    <div className="text-sm font-bold text-indigo-200 flex items-center">
                      <svg className="w-4 h-4 text-green-500 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"></path></svg>
                      <span>Pages will be added each month</span>
                    </div>
                    <div className="text-sm font-bold text-indigo-200 flex items-center">
                      <svg className="w-4 h-4 text-green-500 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"></path></svg>
                      <span>Cancel anytime</span>
                    </div>
                    <div className="text-sm font-bold text-indigo-200 flex items-center">
                      <svg className="w-4 h-4 text-green-500 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7"></path></svg>
                      <span>50 Pages Free Trial</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

const SubscriptionCard = ({ amount, price, priceId, productId, selectedPriceId, onClick }) => {
  return (
    <button
      className={`px-8 py-6 rounded-lg border transition-all duration-300 ${selectedPriceId === priceId
        ? 'border-2 border-indigo-500 bg-indigo-100'
        : 'border border-gray-300 hover:border-indigo-500'
        }`}
      onClick={() => onClick(priceId)}
    >
      <div className="text-2xl font-semibold">{amount.toLocaleString()}</div>
      <div className="text-sm text-gray-500">Pages</div>
    </button>
  );
}