import React from "react";
import { useState, useEffect, useContext, useRef } from 'react'

import { getAuth } from "firebase/auth";
import { get, ref, update, set, query, startAt, endAt, orderByChild, onValue } from "firebase/database";

import { db } from '../utils/firebaseApp.js';
import app from "../utils/firebaseApp.js";

import { AuthContext } from "../utils/auth.js";

import MenuBar from '../components/MenuBar.js';

import { BarChart, DonutChart, AreaChart } from "@tremor/react";

import { Fragment } from 'react'

import { Dialog, Menu, Transition } from '@headlessui/react'

import {
  Bars3Icon,
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'

import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import { PlusIcon, ChevronRightIcon, ArrowRightIcon } from '@heroicons/react/20/solid'

import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  Text,
} from "@tremor/react";

import TopBar from "../components/TopBar.js";

import ModalForYoutubeVideo from '../components/utils/ModalForYoutubeVideo.js';

import posthog from 'posthog-js';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Dashboard() {
  const { currentUser } = useContext(AuthContext);
  let userId = currentUser.uid;

  const [accountObject, setAccountObject] = useState(null);
  const [showVideo, setShowVideo] = useState(false);
  const [showWelcomeToast, setShowWelcomeToast] = useState(false);

  const [sidebarOpen, setSidebarOpen] = useState(false)

  const handleSignout = () => {
    getAuth(app).signOut(app).then(() => {
      // console.log("Signout")
    }).catch((error) => {
      // console.log(error)
    });
  }

  const [extractions, setExtractions] = useState([]);
  const [billing, setBilling] = useState([]);

  // step 1
  const [processedDocuments, setProcessedDocuments] = useState(0);
  const [pendingDocuments, setPendingDocuments] = useState(0);
  const [totalDocuments, setTotalDocuments] = useState(0);

  // step 2
  const [extractionsList, setExtractionsList] = useState([]);
  const [extractionsChartData, setExtractionsChartData] = useState([]);

  // step 3 (a map of each language used in the extractions and the number of extraction that use it)
  const [languages, setLanguages] = useState([]);
  const [languagesChartData, setLanguagesChartData] = useState([]);

  // step 4
  const [timeSaved, setTimeSaved] = useState(0);
  const [costSavings, setCostSavings] = useState(0);

  // step 4
  const [totalPages, setTotalPages] = useState(0);
  const [webPages, setWebPages] = useState(0);
  const [apiPages, setApiPages] = useState(0);
  const [billingChart, setBillingChart] = useState([]);

  const calculateDashboard = (extractions) => {
    let totalDocuments = 0;
    let processedDocuments = 0;
    let pendingDocuments = 0;

    let extractionList = [];

    let languageCounts = {};

    for (const extractionId in extractions) {
      const extraction = extractions[extractionId];

      // step 1
      let localDocuments = 0;
      let localProcessedDocuments = 0;
      let localPendingDocuments = 0;

      for (const batchId in extraction.batches) {
        const batch = extraction.batches[batchId];

        let numberOfDocs;
        try {
          numberOfDocs = batch.files.length;
        } catch (error) {
          numberOfDocs = 0;
        }

        localDocuments += numberOfDocs;
        if (batch.status !== "processing") {
          localProcessedDocuments += numberOfDocs;
        } else {
          localPendingDocuments += numberOfDocs;
        }
      }

      totalDocuments += localDocuments;
      processedDocuments += localProcessedDocuments;
      pendingDocuments += localPendingDocuments;

      // step 2
      extractionList.push({
        id: extractionId,
        name: extraction.name,
        documents: localDocuments,
        processedDocuments: localProcessedDocuments,
        pendingDocuments: localPendingDocuments,
      });

      // step 3
      let localLanguage = extraction.language;
      if (localLanguage) {
        if (!languageCounts[localLanguage]) {
          languageCounts[localLanguage] = localDocuments;
        } else {
          languageCounts[localLanguage] += localDocuments;
        }
      }
    }

    // step 2
    extractionList.forEach((extraction) => {
      extraction.percentage = Math.round((extraction.documents / totalDocuments) * 100);
    });

    // step 3
    let languagesArray = [];
    for (const lang in languageCounts) {
      languagesArray.push({
        language: lang,
        count: languageCounts[lang]
      });
    }

    const languagesChartData = languagesArray.filter(item => item.language !== "").map(item => {
      if (item.language === "") {
        item.language = "Unknown";
      }

      return ({
        name: item.language,
        "Documents with this language": item.count,
      })
    });
    languagesChartData.sort((a, b) => b["Documents with this language"] - a["Documents with this language"]);

    setLanguagesChartData(languagesChartData);

    let topThreeExtractions = extractionList.slice(0, 3); // Take the first 3 extractions
    let remainingExtractions = extractionList.slice(3); // Get the remaining extractions
    let otherDocuments = remainingExtractions.reduce((total, item) => total + item.documents, 0); // Sum of documents for the remaining extractions

    const extractionsChartData = [...topThreeExtractions, { name: "Others", documents: otherDocuments, percentage: Math.round((otherDocuments / totalDocuments) * 100) }];
    extractionsChartData.sort((a, b) => b.documents - a.documents);

    setExtractionsChartData(extractionsChartData);

    setTotalDocuments(totalDocuments);
    setProcessedDocuments(processedDocuments);
    setPendingDocuments(pendingDocuments);

    setExtractionsList(extractionList);
    // console.log(extractionList);

    setLanguages(languagesArray);
    // console.log(languagesArray);

    let timeSaved = totalDocuments * 3.25;
    timeSaved = Math.round(timeSaved);
    let costSavings = totalDocuments * 0.5;
    costSavings = Math.round(costSavings);

    setTimeSaved(timeSaved);
    setCostSavings(costSavings);
  }

  const fetchAllExtractions = async () => {
    const databaseRef = ref(db, 'extractions/' + userId);
    const batchesRef = ref(db, 'batches/' + userId);

    try {
      // Fetch extractions and batches concurrently
      const [extractionsSnapshot, batchesSnapshot] = await Promise.all([
        get(databaseRef),
        get(batchesRef)
      ]);

      if (extractionsSnapshot.exists()) {
        const data = extractionsSnapshot.val();
        const batches = batchesSnapshot.exists() ? batchesSnapshot.val() : {};

        // Update each extraction with its corresponding batches
        for (const extractionId of Object.keys(data)) {
          data[extractionId].batches = batches[extractionId] || null;
        }

        setExtractions(data);
        calculateDashboard(data);
      } else {
        // No data available
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const fetchBilling = async () => {
    const dataRef = ref(db, 'billing/' + userId);

    // overwrite the start and end time for current month
    const today = new Date();
    let startTime = new Date(today.getFullYear(), today.getMonth(), 1);
    let endTime = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    const q = query(dataRef, orderByChild('timestamp'), startAt(startTime.getTime()), endAt(endTime.getTime()));

    get(q).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setBilling(data);

        calculateBilling(data);
      } else {
        // console.log('No data available');
      }
    }).catch((error) => {
      // console.error('Error fetching data:', error);
    });
  }

  function formatDate(dateString) {
    const options = { month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const calculateBilling = (billing) => {
    let totalPages = 0;
    let webPages = 0;
    let apiPages = 0;

    for (const billingId in billing) {
      const billingItem = billing[billingId];

      totalPages += (billingItem.pagesNo ?? 0);

      if (billingItem.origin === "web") {
        webPages += billingItem.pagesNo;
      } else if (billingItem.origin === "api") {
        apiPages += billingItem.pagesNo;
      }
    }

    setTotalPages(totalPages);
    setWebPages(webPages);
    setApiPages(apiPages);

    let totalBillingData = [];

    for (const billingId in billing) {
      const billingItem = billing[billingId];
      const timestamp = billingItem.timestamp;
      const day = formatDate(new Date(timestamp));

      // Check if a billing entry for this timestamp already exists
      const existingEntry = totalBillingData.find((entry) => entry.day === day);

      if (existingEntry) {
        if (billingItem.origin === "web") {
          existingEntry.Web += billingItem.pagesNo;
        } else if (billingItem.origin === "api") {
          existingEntry.API += billingItem.pagesNo;
        }
      } else {
        // Create a new entry for this timestamp
        const newEntry = {
          date: timestamp,
          day: day,
          Web: billingItem.origin === "web" ? billingItem.pagesNo : 0,
          API: billingItem.origin === "api" ? billingItem.pagesNo : 0,
        };

        totalBillingData.push(newEntry);
      }
    }

    // sort the data by timestamp in ascending order
    totalBillingData.sort((a, b) => a.date - b.date);

    setBillingChart(totalBillingData);
  }



  const updateAccountObject = async () => {
    // Reference to the user's database path
    const databaseRef = ref(db, 'userDetails/' + userId + '/account');

    const updatedUserDetails = {
      welcomeVideo: true,
    };

    // Set the data in the database
    try {
      await update(databaseRef, updatedUserDetails);
    } catch (error) {
      console.error("Error updating data:", error);
    }
  }

  const fetchUserDetails = async () => {
    const databaseRef = ref(db, 'userDetails/' + userId + '/account');

    try {
      const snapshot = await get(databaseRef);

      if (snapshot.exists()) {
        const data = snapshot.val();
        setAccountObject(data);

        let welcomeVideo = data.welcomeVideo;
        if (welcomeVideo !== undefined && welcomeVideo !== null) {
          setShowVideo(false);
          setShowWelcomeToast(true);
        } else {
          setShowVideo(true);
          updateAccountObject();
        }
      } else {
        console.log("No data available");
        setAccountObject({});
        setShowVideo(true);
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    const appName = process.env.REACT_APP_NAME;
    document.title = "Dashboard - " + appName;

    const fetchData = async () => {
      await fetchAllExtractions();
      await fetchBilling();
      await fetchUserDetails();

      if (currentUser) {
        // We can add a check to see if we've already identified this user in this session
        if (!window.userIdentified) {
          posthog.identify(currentUser.uid, {
            email: currentUser.email,
            userId: currentUser.uid,
          });
          // Set a flag to avoid redundant identify calls in the same session
          window.userIdentified = true;
        }
      }
    }
    fetchData();
  }, [userId, currentUser]);

  return (
    <>
      <div>
        <MenuBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} handleSignout={handleSignout} currentHref="dashboard" />

        <main className="lg:pl-72">
          <TopBar setSidebarOpen={setSidebarOpen} handleSignout={handleSignout} />

          <div className="py-10 px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col justify-center items-start mb-10">
              <div className='px-3'>
                <a href='/dashboard' className="text-4xl font-bold text-gray-900">Dashboard</a>
              </div>

              {showWelcomeToast && (
                <ShowWelcomeMessage setShowWelcomeToast={setShowWelcomeToast} setShowVideo={setShowVideo} />
              )}

              <ModalForYoutubeVideo open={showVideo} setOpen={setShowVideo} >
                <ContainerForYoutubeVideo
                  closeModal={() => {
                    setShowVideo(false);
                  }}
                  updateAccountObject={updateAccountObject}
                />
              </ModalForYoutubeVideo>

              <div className='w-full px-3 mt-5 mb-50'>
                {/* <h3 className="text-base font-semibold leading-6 text-gray-900">Last 30 days</h3> */}

                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                  <div className="overflow-hidden rounded-lg bg-white px-4 py-5 border shadow-sm sm:p-6">
                    <dt className="truncate text-sm font-medium text-gray-500">Total documents</dt>
                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{totalDocuments}</dd>
                  </div>

                  <div className="overflow-hidden rounded-lg bg-white px-4 py-5 border shadow-sm sm:p-6">
                    <dt className="truncate text-sm font-medium text-gray-500">Processed documents</dt>
                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{processedDocuments}</dd>
                  </div>

                  <div className="overflow-hidden rounded-lg bg-white px-4 py-5 border shadow-sm sm:p-6">
                    <dt className="truncate text-sm font-medium text-gray-500">Pending documents</dt>
                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{pendingDocuments}</dd>
                  </div>

                  <div className="overflow-hidden rounded-lg bg-white px-4 py-5 border shadow-sm sm:p-6 sm:col-span-3">
                    <dt className="truncate text-sm font-medium text-gray-500">Extractions</dt>
                    <div className="flex flex-col sm:flex-row mt-6 h-50">
                      <DonutChartUsageExample2 newChartDataModel={extractionsChartData} className="h-50" />
                      <div className="w-full sm:w-2/3 flex flex-col justify-center">
                        <DonutTable newChartDataModel={extractionsChartData} />
                      </div>
                    </div>
                  </div>

                  {/* <div className="overflow-hidden rounded-lg bg-white px-4 py-5 border shadow-sm sm:p-6">
                    <dt className="truncate text-sm font-medium text-gray-500">Billing</dt>
                    <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                      {totalPages} page(s)
                      <BillingChart billingChart={billingChart} />
                    </dd>
                  </div> */}

                  <div className="flex flex-col justify-between">
                    <div className="h-full overflow-hidden rounded-lg bg-white px-4 py-5 border shadow-sm sm:p-6">
                      <dt className="truncate text-sm font-medium text-gray-500">Time saved</dt>
                      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                        {timeSaved} minutes
                      </dd>
                    </div>
                    <div className="h-12"></div>
                    <div className="h-full overflow-hidden rounded-lg bg-white px-4 py-5 border shadow-sm sm:p-6">
                      <dt className="truncate text-sm font-medium text-gray-500">Cost savings</dt>
                      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                        ${costSavings}
                      </dd>
                    </div>
                  </div>

                  <div className="overflow-hidden rounded-lg bg-white px-4 py-5 border sm:p-6 sm:col-span-2">
                    <dt className="truncate text-sm font-medium text-gray-500">Languages</dt>
                    <BarChartCard newChartDataModel={languagesChartData} />
                  </div>
                </dl>

              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

function ShowWelcomeMessage({ setShowWelcomeToast, setShowVideo }) {
  return (
    <>
      <div className="z-100 pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8">
        <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-gray-900 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
          <p className="text-sm leading-6 text-white">
            <button
              onClick={() => {
                setShowWelcomeToast(false);
                setShowVideo(true);
              }}
            >
              <strong className="font-semibold">Welcome to Extracta.ai!</strong>
              <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                <circle cx={1} cy={1} r={1} />
              </svg>
              Streamline your workflow with automated data extraction
              <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                <circle cx={1} cy={1} r={1} />
              </svg>
              Watch video&nbsp;<span aria-hidden="true">&rarr;</span>
            </button>
          </p>
          <button
            type="button"
            className="-m-1.5 flex-none p-1.5"
            onClick={() => {
              setShowWelcomeToast(false);
            }}
          >
            <span className="sr-only">Dismiss</span>
            <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
    </>
  )
}

const ContainerForYoutubeVideo = ({ closeModal, updateAccountObject }) => {
  const iframeRef = useRef(null);

  const toggleFullscreen = () => {
    if (iframeRef.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        iframeRef.current.requestFullscreen().catch(e => {
          console.log("Error attempting to enable full-screen mode:", e);
        });
      }
    }
  };

  const skipFunction = () => {
    updateAccountObject();
    closeModal();
  }

  const watchFunction = () => {
    updateAccountObject();
    toggleFullscreen();
  }

  return (
    <div className="modal-container" style={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: '20px 20px 20px 20px', // Space between the border and content
    }}>
      <h1 className="mt-2 text-2xl font-semibold text-gray-900">
        Welcome to Extracta.ai! 🚀
      </h1>
      {/* add secondary small text */}
      <p className="mb-3.5 text-base text-gray-500">
        Streamline your workflow with automated data extraction. Watch the video below to get started.
      </p>
      <iframe
        ref={iframeRef}
        width="100%"
        height="95%" // Adjust the height based on your requirements
        src="https://www.youtube.com/embed/D_Jpj2Ab1fw?autoplay=1&controls=1"
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
        title="Welcome Video - Extracta.ai"
        style={{ marginBottom: '10px', borderRadius: '5px' }}
      ></iframe>
      <div className="mt-4 flex items-center justify-end">
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => skipFunction()}
        >
          Skip
        </button>
        <button
          type="button"
          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
          onClick={() => watchFunction()}
        >
          <ArrowRightIcon className="-ml-0.5 mr-1 h-5 w-5" aria-hidden="true" />
          Watch Video
        </button>
      </div>
    </div>
  );
}

function BarChartCard({ newChartDataModel }) {
  return (
    <BarChart
      className="mt-6"
      data={newChartDataModel}
      index="name"
      categories={["Documents with this language"]}
      colors={["indigo"]}
      yAxisWidth={48}
      showAnimation={true}
    />
  )
}

const donutColors = ["indigo", "rose", "emerald", "orange"];

const DonutChartUsageExample2 = ({ newChartDataModel }) => {
  return (
    <>
      <DonutChart
        className="w-full sm:w-1/3 h-48 m-auto"
        data={newChartDataModel}
        category="documents"
        index="name"
        colors={donutColors}
        variant="pie"
        showAnimation={true}
      />
    </>
  );
};

const DonutTable = ({ newChartDataModel }) => {
  return (
    <Table>
      <TableBody>
        {newChartDataModel.map((item, index) => (
          <TableRow key={index}>
            <TableCell className="flex flex-row items-center">
              <div className={classNames("mr-2 h-3 w-3 rounded-full", "bg-" + donutColors[index] + "-500")}></div>
              {item.name ?? '-'}
            </TableCell>
            <TableCell>
              <Text>{item.documents} documents</Text>
            </TableCell>
            <TableCell>
              <Text>{item.percentage}%</Text>
            </TableCell>
          </TableRow>
        ))}
        <TableRow>
          <TableCell className="font-bold">
            Total
          </TableCell>
          <TableCell>
            <Text className="font-bold">{newChartDataModel.reduce((total, item) => total + item.documents, 0)} documents</Text>
          </TableCell>
          <TableCell>
            <Text className="font-bold">100%</Text>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}

const customTooltip = ({ payload, active, label }) => {
  if (!active || !payload) return null;
  return (
    <div className="w-56 rounded-tremor-default text-tremor-default bg-tremor-background p-2 shadow-tremor-dropdown border border-tremor-border">
      <p className="font-medium text-tremor-content-emphasis mb-1">{label}</p>
      <div className="flex flex-row">
        {payload.map((category, idx) => (
          <div key={idx} className="flex flex-1 space-x-2.5 mt-1">
            <div className={`w-1 flex flex-col bg-${category.color}-500 rounded`} />
            <div className="space-y-1">
              <p className="text-tremor-content">{category.dataKey}</p>
              <p className="font-medium text-tremor-content-emphasis">{category.value} pages</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const valueFormatter = function (number) {
  return number.toString() + ' pages';
};

const BillingChart = ({ billingChart }) => {
  return (
    <AreaChart
      className="h-60 mt-4"
      data={billingChart}
      index="day"
      categories={["Web", "API"]}
      colors={["indigo", "cyan"]}
      showAnimation={true}
      showLegend={true}
      allowDecimals={false}
      valueFormatter={valueFormatter}
      customTooltip={customTooltip}
      yAxisWidth={68}
    />
  );
}

