import { Fragment, useState, useEffect, useContext, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { DocumentTextIcon } from '@heroicons/react/24/outline'; // For a 24px outline icon

import app from "../utils/firebaseApp.js";
import { db } from '../utils/firebaseApp.js';
import { getAuth } from "firebase/auth";
import { AuthContext } from "../utils/auth.js";
import { get, set, ref, update } from "firebase/database";

import axios from "axios";

import MenuBar from '../components/MenuBar.js';

import { XMarkIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon, ExclamationCircleIcon, ClipboardDocumentIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'

import TopBar from "../components/TopBar.js";

export default function Api() {
  const { currentUser } = useContext(AuthContext);
  let userId = currentUser.uid;
  let userEmail = currentUser.email;
  let userFullName = currentUser.displayName;

  // get first name and last name from firebase
  const [firstName, setFirstName] = useState("extracta-first-name");
  const [lastName, setLastName] = useState("extracta-last-name");

  const serverUrl = process.env.REACT_APP_SERVER_URL;
  const environment = process.env.REACT_APP_ENV;

  const [sidebarOpen, setSidebarOpen] = useState(false)

  const handleSignout = () => {
    getAuth(app).signOut(app).then(() => {
      // console.log("Signout")
    }).catch(() => {
      // console.log(error)
    });
  }

  const [apiObject, setApiObject] = useState(null);

  const [webhookObject, setWebhookObject] = useState(null);
  const [showWebhookAdd, setShowWebhookAdd] = useState(false);
  const [showWebhookEdit, setShowWebhookEdit] = useState(false);

  const [showDeleteWebhook, setShowDeleteWebhook] = useState(false);
  const [showDeleteApi, setShowDeleteApi] = useState(false);

  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showCopy, setShowCopy] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const deleteApiKey = (callback) => {
    currentUser.getIdToken().then((idToken) => {
      const url = serverUrl + "/deleteApiKey";

      let apiKey = apiObject[0].apiKey;
      console.log("apiKey", apiKey);

      axios.post(url, {
        apiKey: apiKey
      }, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${idToken}`
        }
      }).then((response) => {
        // console.log(response.data);
        callback(response.data);
      }).catch((error) => {
        console.log(error);
        callback(null);
      });
    });
  }

  const createApiKey = (callback) => {
    currentUser.getIdToken().then((idToken) => {
      const url = serverUrl + "/createApiKey";

      axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${idToken}`
        }
      }).then((response) => {
        // console.log(response.data);
        callback(response.data.apiKey);
      }).catch((error) => {
        console.log(error);
        callback(null);
      });
    });
  }

  const deleteWebhookEndpoint = (callback) => {
    currentUser.getIdToken().then((idToken) => {
      const url = serverUrl + "/deleteClientSecret";

      axios.post(url, {}, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${idToken}`
        }
      }).then((response) => {
        // console.log(response.data);
        callback(response.data);
      }).catch((error) => {
        console.log(error);
        callback(null);
      });
    });
  }

  const createWebhookEndpoint = (endpoint, callback) => {
    currentUser.getIdToken().then((idToken) => {
      const url = serverUrl + "/generateClientSecret";

      axios.post(url, {
        "url": endpoint,
      }, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${idToken}`
        }
      }).then((response) => {
        // console.log(response.data);
        callback(response.data);
      }).catch((error) => {
        console.log(error);
        callback(null);
      });
    });
  }

  const editWebhookEndpoint = (endpoint, callback) => {
    currentUser.getIdToken().then((idToken) => {
      // Get a reference to the user's webhook data in firebase
      const databaseRef = ref(db, 'userDetails/' + userId + '/webhook');

      // Prepare the data to be updated
      const updates = {
        url: endpoint,
        updatedAt: new Date().toISOString()
      };

      // Update the fields in the database
      try {
        // This will only update 'url' and 'updatedAt' fields
        update(databaseRef, updates).then(() => {
          // Success callback
          callback(true);
        }).catch((error) => {
          // Error callback
          callback(false);
        });
      } catch (error) {
        // Error callback
        callback(false);
      }
    });
  }

  const handleDeleteApiKey = async () => {
    setIsLoading(true);

    deleteApiKey(async (response) => {
      if (!response) {
        showErrorFunction();
        return;
      }

      try {
        // showSuccessFunction();
        await new Promise(r => setTimeout(r, 1000));
        await fetchUserDetails();
      } catch (error) {
        showErrorFunction();
      }
    });

    setIsLoading(false);
  };

  const handleCreateApiKey = async (event) => {
    event.preventDefault(); // Prevent the default form submit behavior
    setIsLoading(true);

    createApiKey(async (apiKey) => {
      if (!apiKey) {
        showErrorFunction();
        return;
      }

      console.log("apiKey", apiKey);

      // Create an object with the data
      const apiLocalObject = {
        name: "Default",
        apiKey: apiKey,
        createdAt: new Date().toISOString(),
      };

      // Reference to the user's database path
      const databaseRef = ref(db, 'userDetails/' + userId + '/api');

      // Set the data in the database
      try {
        await set(databaseRef, [apiLocalObject, ...apiObject]);
        showSuccessFunction();
        await new Promise(r => setTimeout(r, 1500));
        await fetchUserDetails();
      } catch (error) {
        showErrorFunction();
      }
    });

    setIsLoading(false);
  };

  const handleDeleteWebhookEndpoint = async () => {
    setIsLoading(true);

    deleteWebhookEndpoint(async (response) => {
      if (!response) {
        showErrorFunction();
        return;
      }

      try {
        // showSuccessFunction();
        await new Promise(r => setTimeout(r, 1000));
        await fetchUserDetails();
      } catch (error) {
        showErrorFunction();
      }
    });

    setIsLoading(false);
  };

  const handleCreateWebhookEndpoint = async (endpoint) => {
    setIsLoading(true);

    createWebhookEndpoint(endpoint, async (response) => {
      if (!response) {
        showErrorFunction();
        return;
      }

      try {
        showSuccessFunction();
        await new Promise(r => setTimeout(r, 1000));
        await fetchUserDetails();
      } catch (error) {
        showErrorFunction();
      }
    });

    setIsLoading(false);
  };

  const handleEditWebhookEndpoint = async (endpoint) => {
    setIsLoading(true);

    editWebhookEndpoint(endpoint, async (response) => {
      if (!response) {
        showErrorFunction();
        return;
      }

      try {
        showSuccessFunction();
        await new Promise(r => setTimeout(r, 1000));
        await fetchUserDetails();
      } catch (error) {
        showErrorFunction();
      }
    });

    setIsLoading(false);
  };

  const showErrorFunction = () => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 2000);
  }

  const showSuccessFunction = () => {
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 2000);
  }

  const showCopyFunction = () => {
    setShowCopy(true);
    setTimeout(() => {
      setShowCopy(false);
    }, 2000);
  }

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    showCopyFunction();
  }

  const fetchUserDetails = async () => {
    const databaseRef = ref(db, 'userDetails/' + userId);

    try {
      const snapshot = await get(databaseRef);

      if (snapshot.exists()) {
        const data = snapshot.val();

        setApiObject(data.api ?? []);
        setWebhookObject(data.webhook != null ? [data.webhook] : []);

        // First try to get name from auth
        if (userFullName) {
          let nameParts = userFullName.split(" ");
          if (nameParts.length >= 2) {
            setFirstName(nameParts[0]);
            setLastName(nameParts[1]);
            return; // Exit if we successfully set the name
          }
        }

        // Then try to get name from database
        let accountDetails = data.account;
        let fullName = accountDetails?.fullName ?? "";

        if (fullName) {
          let nameParts = fullName.split(" ");
          if (nameParts.length >= 2) {
            setFirstName(nameParts[0]);
            setLastName(nameParts[1]);
            return; // Exit if we successfully set the name
          }
        }

        // Finally fallback to email
        let emailParts = userEmail.split("@");
        setFirstName(emailParts[0] || "");
        setLastName(emailParts[0] || "");

      } else {
        // console.log("No data available");
        setApiObject([]);
        setWebhookObject([]);

        // First try to get name from auth
        if (userFullName) {
          let nameParts = userFullName.split(" ");
          if (nameParts.length >= 2) {
            setFirstName(nameParts[0]);
            setLastName(nameParts[1]);
            return; // Exit if we successfully set the name
          }
        }

        // Finally fallback to email
        let emailParts = userEmail.split("@");
        setFirstName(emailParts[0] || "");
        setLastName(emailParts[0] || "");
      }
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    const appName = process.env.REACT_APP_NAME;
    document.title = "API - " + appName;

    const fetchData = async () => {
      await fetchUserDetails();
    }
    fetchData();
  }, [userId]);


  return (
    <>
      <ErrorNotification showError={showError} setShowError={setShowError} />
      <GoodNotification showError={showSuccess} setShowError={setShowSuccess} />
      <CopyNotification showError={showCopy} setShowError={showCopy} />

      <ModalWebhook open={showWebhookAdd} setOpen={setShowWebhookAdd}
        addFunction={(endpoint) => {
          handleCreateWebhookEndpoint(endpoint);
          setShowWebhookAdd(false);
        }}
      />

      {webhookObject && webhookObject.length !== 0 &&
        <ModalWebhookEdit open={showWebhookEdit} setOpen={setShowWebhookEdit}
          addFunction={(endpoint) => {
            handleEditWebhookEndpoint(endpoint);
            setShowWebhookEdit(false);
          }}
          // if webhook object is empty then set endpoint to empty string
          endpoint={webhookObject.length !== 0 ? webhookObject[0].url : ""}
        />
      }

      <ShowDeleteModalWebhook open={showDeleteWebhook} setOpen={setShowDeleteWebhook} deleteFunction={() => {
        handleDeleteWebhookEndpoint();
        setShowDeleteWebhook(false);
      }} />

      <ShowDeleteModalApi open={showDeleteApi} setOpen={setShowDeleteApi} deleteFunction={() => {
        handleDeleteApiKey();
        setShowDeleteApi(false);
      }} />

      <div>
        <MenuBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} handleSignout={handleSignout} currentHref="api" />

        <main className="lg:pl-72">
          <TopBar setSidebarOpen={setSidebarOpen} handleSignout={handleSignout} />

          <div className="py-10 px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col justify-center items-start">
              <div className='px-3'>
                <a href='/api' className="text-4xl font-bold text-gray-900">API</a>
                <p className="text-m text-gray-500"></p>
              </div>
            </div>

            <div className='w-full px-3 mt-10'>
              <ApiKeysTable
                handleCopy={handleCopy}
                apiObject={apiObject}
                handleCreateApiKey={handleCreateApiKey}
                isLoading={isLoading}
                setShowDeleteApi={() => setShowDeleteApi(true)}
              />
            </div>

            <div className='w-full px-3 mt-10'>
              <ApiWebhookTable
                handleCopy={handleCopy}
                apiObject={webhookObject}
                handleCreateApiKey={() => setShowWebhookAdd(true)}
                isLoading={isLoading}
                setShowWebhookEdit={() => setShowWebhookEdit(true)}
                setShowDeleteWebhook={() => setShowDeleteWebhook(true)}
              />
            </div>

            <div className='w-full px-3 mt-10'>
              <zapier-workflow
                sign-up-email={userEmail}
                sign-up-first-name={firstName}
                sign-up-last-name={lastName}
                client-id="rSXz6WwFtLLshVtuCeDpXm34Fqx2umxyiz0eZQOB"
                theme="light"
                intro-copy-display="show"
                manage-zaps-display="hide"
                guess-zap-display="show"
                template-cta-display="show"
                zap-create-from-scratch-display="hide"
              />
            </div>

            {/* add a divider */}
            <div className="border-t border-gray-200 my-10"></div>

            <div className='w-full px-3 mt-10'>
              <ApiLinkBox />
            </div>
          </div>
        </main>
      </div>
    </>
  )
}

const docsLink = 'https://docs.extracta.ai';

const ApiLinkBox = () => {
  const handleButtonClick = () => {
    // This will navigate to the docs link when the button is clicked
    // window.location.href = docsLink;
    window.open(docsLink, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="mx-auto my-8">
      <div className="bg-gray-100 rounded-xl p-6">
        <h2 className="text-xl font-bold text-gray-800 mb-1.5">API Documentation</h2>
        <button
          onClick={handleButtonClick}
          className="flex items-center text-indigo-500 hover:text-indigo-700 font-medium text-lg transition-colors duration-200"
        >
          <DocumentTextIcon className="h-6 w-6 mr-2" aria-hidden="true" />
          <span>Read the API documentation</span>
        </button>
      </div>
    </div>
  );
};

function ApiWebhookTable({ handleCopy, apiObject, handleCreateApiKey, isLoading, setShowWebhookEdit, setShowDeleteWebhook }) {
  if (apiObject === null) return (
    <div className="flex-1 flex items-center justify-center h-32">
      <div role="status">
        <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )

  let isApiKey = apiObject.length !== 0 ? true : false;
  let apiKeys = [];

  if (isApiKey) {
    apiObject.forEach((apiObjectKey) => {
      apiKeys.push({
        name: "Default",
        endpointUrl: apiObjectKey.url,
        secretKey: apiObjectKey.secret,
        createdAt: apiObjectKey.createdAt
      });
    });
  }

  function formatDate(isoDateString) {
    const date = new Date(isoDateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      // The date is not valid
      console.error("Invalid date string provided:", isoDateString);
      return null; // or handle this however appropriate for your application
    }

    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      // timeZoneName: 'short' // consider including the time zone name if relevant
    };
    return date.toLocaleDateString(undefined, options);
  }

  function obscureApiKey(apiKey) {
    // Check if the apiKey is a string and has sufficient length
    if (typeof apiKey === 'string' && apiKey.length > 5) {
      // Determine the number of characters to obscure (you can adjust this as needed)
      const charsToShow = 4;
      const charsToObscure = apiKey.length - charsToShow;
      const obscuredSection = '*'.repeat(charsToObscure);

      // Construct the obscured key string
      return apiKey.substr(0, charsToShow) + obscuredSection;
    } else {
      // Handle cases where the apiKey is not a valid string or is too short
      console.error("Invalid API key provided");
      return null;
    }
  }

  function limitString(str, limit) {
    // Check if the string length is greater than 15
    if (str.length > limit) {
      // If so, return the substring of the first 15 characters plus an ellipsis
      return str.substring(0, limit) + '...';
    } else {
      // If not, return the original string
      return str;
    }
  }

  return (
    <div className="">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold leading-6 text-gray-900">Webhook Settings</h1>
          <p className="mt-2 text-sm text-gray-700">
            Here you can modify the endpoint url and secret key for your webhook.
          </p>
        </div>
        {!isApiKey &&
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              onClick={handleCreateApiKey}
              disabled={isLoading}
              // disabled={true}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Create a new endpoint
            </button>
          </div>
        }
      </div>
      {apiKeys.length !== 0 &&
        <div className="mt-4 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      NAME
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      ENDPOINT URL
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      SECRET KEY
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      CREATED AT
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      EDIT
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      DELETE
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {apiKeys.map((apiKey) => (
                    <tr key={apiKey.name}>
                      <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {apiKey.name}
                      </td>
                      <td className="whitespace-nowrap py-3 px-3 text-sm text-gray-500">
                        <div className="flex items-center">
                          {limitString(apiKey.endpointUrl, 25)}
                          <button
                            type="button"
                            onClick={() => { handleCopy(apiKey.endpointUrl) }}
                            className="ml-2 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            <ClipboardDocumentIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </td>
                      <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                        <div className="flex items-center"> {/* Wrap apiKey.secretKey and button in a div with 'flex items-center' classes */}
                          {limitString(obscureApiKey(apiKey.secretKey), 15)}
                          <button
                            type="button"
                            onClick={() => { handleCopy(apiKey.secretKey) }}
                            className="ml-2 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          >
                            <ClipboardDocumentIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </td>
                      <td className="whitespace-nowrap py-3 px-3 text-sm text-gray-500">{formatDate(apiKey.createdAt)}</td>
                      {/* add edit button */}
                      <td className="whitespace-nowrap py-3 px-3 text-sm text-gray-500">
                        <button
                          type="button"
                          onClick={() => { setShowWebhookEdit() }}
                          className="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          Edit
                        </button>
                      </td>
                      {/* add delete button */}
                      <td className="whitespace-nowrap py-3 px-3 text-sm text-gray-500">
                        <button
                          type="button"
                          onClick={() => { setShowDeleteWebhook() }}
                          className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      }
      {!isApiKey && !isLoading &&
        <div className="mt-4 flex-1 flex flex-col items-center justify-center h-full rounded-lg bg-gray-100 p-8">
          <div className="text-center">
            <h2 className="text-xl font-semibold text-gray-900">No Webhook endpoint yet</h2>
            <p className="mt-2 text-sm text-gray-500">
              Your webhook endpoint will appear here once you create it.
            </p>
          </div>
        </div>
      }
    </div>
  )
}

function ShowDeleteModalWebhook({ open, setOpen, deleteFunction }) {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-100" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Confirm Deletion
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete this webhook endpoint? This action cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={() => deleteFunction()}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function ModalWebhook({ open, setOpen, addFunction }) {
  const cancelButtonRef = useRef(null);

  const [endpointUrl, setEndpointUrl] = useState("");
  const formRef = useRef(null);

  const handleInputChange = (e) => {
    // e.preventDefault();
    setEndpointUrl(e.target.value);
  };

  const saveFunction = () => {
    formRef.current && formRef.current.reportValidity();

    if (endpointUrl.length === 0) {
      return;
    }

    addFunction(endpointUrl);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-100" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-indigo-600" aria-hidden="true" />
                    </div> */}
                    <div className="mt-3 text-center sm:mt-0 sm:text-left"> {/* sm:ml-4 */}
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Add webhook endpoint
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Add a new webhook endpoint to receive data when a new extraction is completed.
                        </p>
                      </div>
                      <form className="mt-5 sm:flex sm:items-center" ref={formRef}>
                        <div className="w-full">
                          <label htmlFor="email" className="sr-only">
                            Endpoint URL
                          </label>
                          <input
                            type="text"
                            name="endpoint"
                            id="endpoint"
                            value={endpointUrl}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="https://api.example.com/webhookExtracta"
                            required={true}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                    onClick={() => saveFunction()}
                  >
                    Add endpoint
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function ModalWebhookEdit({ endpoint, open, setOpen, addFunction }) {
  const cancelButtonRef = useRef(null);

  const [endpointUrl, setEndpointUrl] = useState(endpoint);
  const formRef = useRef(null);

  const handleInputChange = (e) => {
    // e.preventDefault();
    setEndpointUrl(e.target.value);
  };

  const saveFunction = () => {
    formRef.current && formRef.current.reportValidity();

    if (endpointUrl.length === 0) {
      return;
    }

    addFunction(endpointUrl);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-100" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="w-full bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left"> {/* sm:ml-4 */}
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Edit webhook endpoint
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Edit the endpoint url and secret key for your webhook.
                        </p>
                      </div>
                      <form className="mt-5 sm:flex sm:items-center" ref={formRef}>
                        <div className="w-full">
                          <label htmlFor="email" className="sr-only">
                            Endpoint URL
                          </label>
                          <input
                            type="text"
                            name="endpoint"
                            id="endpoint"
                            value={endpointUrl}
                            onChange={handleInputChange}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="https://api.example.com/webhookExtracta"
                            required={true}
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto"
                    onClick={() => saveFunction()}
                  >
                    Edit endpoint
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function ApiKeysTable({ handleCopy, apiObject, handleCreateApiKey, isLoading, setShowDeleteApi }) {
  if (apiObject === null) return (
    <div className="flex-1 flex items-center justify-center h-32">
      <div role="status">
        <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  )

  let isApiKey = apiObject.length !== 0 ? true : false;
  let apiKeys = [];

  if (isApiKey) {
    apiObject.forEach((apiObjectKey) => {
      apiKeys.push({
        name: "Default",
        apiKey: apiObjectKey.apiKey,
        createdAt: apiObjectKey.createdAt
      });
    });
  }

  function formatDate(isoDateString) {
    const date = new Date(isoDateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      // The date is not valid
      console.error("Invalid date string provided:", isoDateString);
      return null; // or handle this however appropriate for your application
    }

    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      // timeZoneName: 'short' // consider including the time zone name if relevant
    };
    return date.toLocaleDateString(undefined, options);
  }

  function obscureApiKey(apiKey) {
    // Check if the apiKey is a string and has sufficient length
    if (typeof apiKey === 'string' && apiKey.length > 5) {
      // Determine the number of characters to obscure (you can adjust this as needed)
      const charsToShow = 4;
      const charsToObscure = apiKey.length - charsToShow;
      const obscuredSection = '*'.repeat(charsToObscure);

      // Construct the obscured key string
      return apiKey.substr(0, charsToShow) + obscuredSection;
    } else {
      // Handle cases where the apiKey is not a valid string or is too short
      console.error("Invalid API key provided");
      return null;
    }
  }

  return (
    <div className="">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-xl font-semibold leading-6 text-gray-900">API Key</h1>
          <p className="mt-2 text-sm text-gray-700">
            Here you can generate a new API key that will be used to authenticate your requests.
          </p>
        </div>
        {!isApiKey &&
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              onClick={handleCreateApiKey}
              disabled={isLoading}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Create a new API key
            </button>
          </div>
        }
      </div>
      {apiKeys.length > 0 &&
        <div className="mt-4 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      NAME
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      KEY
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      CREATED AT
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      DELETE
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {apiKeys.map((apiKey) => (
                    <tr key={apiKey.createdAt}>
                      <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        {apiKey.name}
                      </td>
                      <td className="whitespace-nowrap py-3 px-3 text-sm text-gray-500 flex flex-row items-center">
                        {obscureApiKey(apiKey.apiKey)}
                        <button
                          type="button"
                          onClick={() => { handleCopy(apiKey.apiKey) }}
                          className="ml-2 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          <ClipboardDocumentIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                        </button>
                      </td>
                      <td className="whitespace-nowrap py-3 px-3 text-sm text-gray-500">{formatDate(apiKey.createdAt)}</td>
                      {/* add delete button */}
                      <td className="whitespace-nowrap py-3 px-3 text-sm text-gray-500">
                        <button
                          type="button"
                          onClick={() => { setShowDeleteApi() }}
                          className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      }
      {!isApiKey && !isLoading &&
        <div className="mt-4 flex-1 flex flex-col items-center justify-center h-full rounded-lg bg-gray-100 p-8">
          <div className="text-center">
            <h2 className="text-xl font-semibold text-gray-900">No API Keys yet</h2>
            <p className="mt-1.5 text-sm text-gray-600">
              Your API keys will appear here once you create them.
            </p>
          </div>
        </div>
      }
    </div>
  )
}

function ShowDeleteModalApi({ open, setOpen, deleteFunction }) {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-100" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Confirm Deletion
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete this API key? This action cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={() => deleteFunction()}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

function GoodNotification({ showError, setShowError }) {
  const successfulTitle = 'Success!';
  const successfulSubtitle = 'Your API/Webhook key has been created successfully.';

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="z-100 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={showError}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{successfulTitle}</p>
                    <p className="mt-1 text-sm text-gray-500">{successfulSubtitle}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShowError(false)
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}

function ErrorNotification({ showError, setShowError }) {
  const errorTitle = 'Error!';
  const errorSubtitle = 'There was an issue. Please try again.';

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="z-100 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={showError}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{errorTitle}</p>
                    <p className="mt-1 text-sm text-gray-500">{errorSubtitle}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShowError(false)
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}

function CopyNotification({ showError, setShowError }) {
  const copyTitle = 'Copied!';
  const copySubtitle = 'Your text has been copied to your clipboard.';

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="z-100 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={showError}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <ClipboardDocumentIcon className="h-6 w-6 text-indigo-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{copyTitle}</p>
                    <p className="mt-1 text-sm text-gray-500">{copySubtitle}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        setShowError(false)
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  )
}

