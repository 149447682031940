// List of blocked IPs - you can load this from a database or API in a production environment
const BLOCKED_IPS = [
    "42.105.181.74",
    "185.225.234.26",
    "103.174.160.132",
    "180.252.206.163",
    "103.126.150.129",
    "103.166.41.10",
    // Add more blocked IPs as needed
];

/**
 * Checks if an IP address is in the blocked list
 * @param {string} ipAddress - The IP address to check
 * @returns {boolean} - True if the IP is blocked, false otherwise
 */
export const isIPBlocked = (ipAddress) => {
    return BLOCKED_IPS.includes(ipAddress);
};

/**
 * Gets the client's IP address
 * @returns {Promise<string>} - Promise that resolves to the IP address
 */
export const getClientIP = async () => {
    try {
        // Using a public API to get the client's IP address
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip;
    } catch (error) {
        console.error("Error fetching IP address:", error);
        return null;
    }
};

const getUserAgent = () => {
    try {
        return {
            browser: navigator.userAgent,
            language: navigator.language,
            platform: navigator.platform,
            screenResolution: `${window.screen.width}x${window.screen.height}`,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            timestamp: new Date().toISOString()
        };
    } catch (error) {
        return null;
    }
};

const serverUrl = process.env.REACT_APP_SERVER_URL;

export const storeRegistrationInfo = async ({ type, registrationType, idToken, clientIP }) => {
    try {
        let deviceType = null;
        try {
            deviceType = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile/.test(navigator.userAgent)
                ? 'mobile'
                : 'desktop';
        } catch (error) {
        }

        const url = serverUrl + "/storeDetails/" + type;

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${idToken}`
            },
            body: JSON.stringify({
                ip: clientIP,
                registrationType: registrationType,
                userAgent: getUserAgent(),
                deviceType: deviceType,
                timestamp: new Date().toISOString(),
            })
        });
        if (!response.ok) {
        }
    } catch (error) {
    }
}